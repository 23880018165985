import { ModuleWithProviders, NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items';
import {TranslateModule} from '@ngx-translate/core';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './accordion';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MadPipe } from './pipes/mad_pipe';
import { JesusPipe } from './pipes/jesus_pipe';

@NgModule({
  imports: [TranslateModule,CommonModule, RouterModule],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MadPipe,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    TranslateModule,
    MadPipe,

  ],
  providers: [MenuItems, HorizontalMenuItems]
})
export class SharedModule {}
