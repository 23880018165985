import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import { Subject } from 'rxjs';
import { AnyRecord } from 'dns';
@Injectable({
  providedIn: 'root'
})

export class VerticalSidebarService {

  header: any;
  onDataChanged: Subject<any>;
  constructor(private _http: HttpClient, private _helper: Helper) {
    this.onDataChanged = new Subject();
  }

  getCompanyCounterProcurement(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'procurement/getCompanyToReviewedCount', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getCompanyQualifiedProcurement(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'procurement/getCountSuppliersToBeQualified', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getCounterListRequest(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getCounterListRequest', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  getCodificValues(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'encoded_value/list', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setTutorialPage(obj: any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      if (obj.id) {
        this._http.post(this._helper.getBaseUrl() + 'auth/setTutorialPage', { id: obj.id, page_id: obj.page_id, name_page: obj.name_page, state: obj.state }, this.header)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      } else {
        this._http.post(this._helper.getBaseUrl() + 'auth/setTutorialPage', { page_id: obj.page_id, name_page: obj.name_page, state: obj.state }, this.header)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);
      }

    });
  }

  getCounterListInvite(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getCounterListInvite', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getCompanyCounter(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/countCompanyPending', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getServicesCounter(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'services/getCountServiceFromApproved', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getCounterServiceApproved(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'services/getCountServiceFromApproved', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getChatNewMessages(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'conversations/getChatsUnreads', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getDocumentationStatus(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getDocumentationStatus', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getPlanStatus(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getPlanStatus', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getRefreshToken(token : any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'auth/refresh', {token : token, extend_session: 0}, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setHeader() {
    this.header = {
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${this._helper.getUserConfigData().access_token}`)
    };
  }

  getNumberCredits(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'company/getNumberCredits', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getLogoCompany(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'auth/getLogo')
        .subscribe((response: any) => {
          resolve(response);
        });
    });
  }

  getFunctionCompany(): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.get(this._helper.getBaseUrl() + 'functions/getFunctionCompany', this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  setSendFeedback(setFeedback : any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'company/setSendFeedback',  {send_feedback : setFeedback},  this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getCheckSubscriptionIsUpdated(plan_id: number, date: any): Promise<any> {
    this.setHeader();
    return new Promise((resolve, reject) => {
      this._http.post(this._helper.getBaseUrl() + 'chargebee/getCheckSubscriptionIsUpdated', { plan_id: plan_id, date: date }, this.header)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}
