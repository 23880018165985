import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgZone, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Helper } from 'src/helper/helper';
import { AppComponent as father } from '../app.component'
import { I } from '@angular/cdk/keycodes';
import { CompleteProfileModalGlobalServices } from './complete-profile-modal-global.service';
import { FullComponent } from '../layouts/full/full.component';
@Component({
	selector: 'app-complete-profile-modal-global',
	templateUrl: './complete-profile-modal-global.component.html',
	styleUrls: ['./complete-profile-modal-global.component.scss']
})

export class CompleteProfileModalGlobal implements OnInit {
	action: string;
	local_data: any;
	disabled: boolean = false;
	loading: boolean = false;
	horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
	verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom
	token: any;
	currentUser: any;

	view_modal_flag: any = 0;
	canSkipModal = 0;
	assumedUser: any;
	versionProduct: any;




	constructor(private fullComponent: FullComponent, private http: HttpClient, private translate: TranslateService, private _service: CompleteProfileModalGlobalServices, private _helper: Helper, private ngZone: NgZone, private router: Router, public datePipe: DatePipe, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<CompleteProfileModalGlobal>,
		// @Optional() is used to prevent error if no data is passed
		@Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
		this.local_data = { ...data };
		//console.log(this.local_data);

		dialogRef.disableClose = true;
		this.action = this.local_data.action;
		this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		setTimeout(() => {
			let modal = document.getElementsByClassName('custom-modal')[0];
			let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
			modal.setAttribute('style', 'opacity:1');
			modalBase.setAttribute('style', 'display:block');
		}, 400);
	}

	ngOnInit(): void {
		this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);
		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		if (((this.currentUser.possible_skip_modal_complete == 1 || this.currentUser.possible_skip_modal_complete == '1') && this.currentUser.isProfessionalServices == 1 && this.currentUser.required_survey == 1)
			|| ((this.currentUser.possible_skip_modal_complete == 1 || this.currentUser.possible_skip_modal_complete == '1') && this.currentUser.required_survey == 0)) {
			this.canSkipModal = 1;
			let link = <HTMLElement>document.getElementById('jump-off')
			link.setAttribute('style', 'display:flex')
		} else {
			this.canSkipModal = 0;
			let link = <HTMLElement>document.getElementById('jump-off')
			link.setAttribute('style', 'display:none')
		}
	}

	ngAfterViewInit(): void {
		if (((this.currentUser.possible_skip_modal_complete == 1 || this.currentUser.possible_skip_modal_complete == '1') && this.currentUser.isProfessionalServices == 1 && this.currentUser.required_survey == 1)
			|| ((this.currentUser.possible_skip_modal_complete == 1 || this.currentUser.possible_skip_modal_complete == '1') && this.currentUser.required_survey == 0)) {
			this.canSkipModal = 1;
			let link = <HTMLElement>document.getElementById('jump-off')
			link.setAttribute('style', 'display:flex')
		} else {
			this.canSkipModal = 0;
			let link = <HTMLElement>document.getElementById('jump-off')
			link.setAttribute('style', 'display:none')
		}

	}


	jumpOff() {
		this.view_modal_flag = 0;
		var objSegment = {};

		objSegment = {
			action: ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Skip modale completa profilo' : this.assumedUser == 1 ? '[STAFF] Skip modale completa profilo' : 'Skip modale completa profilo'),
		};

		if (this.fullComponent.activeSegmentDev == true) {

			this.fullComponent.trackSegment(objSegment);
		}

		this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);


		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

		if (this.versionProduct == 2) {

			/* IA - SEGMENT */
			if (window.location.host == 'marketplace.timeflow.it') {
				this.fullComponent.trackSegment(objSegment);
			}
		} else {

			if (window.location.host == 'localhost:4200') {

			} else {

				if (window.location.host == "procurement.timeflow.it") {
					this.fullComponent.trackSegment(objSegment);

				} else if (window.location.host == "whitelabel.daytrend.it") {

				} else if (window.location.host == "procurement.daytrend.it") {

				} else if (window.location.host == "dev.mkt.daytrend.it") {

				} else {
					if (!(window.location.host.includes('daytrend'))) {

						this.fullComponent.trackSegment(objSegment);

					}
				}
			}
		}

		this._service.addCountSkip(1).then(res => {

		})
		this.closeDialog('Skip')

	}
	goToProfile() {
		this.closeDialog('Profile Complete')
	}
	ngOnDestroy(): void {


	}

	closeDialog(event: any) {
		let modalBase = document.getElementsByClassName('mat-dialog-container')[0];
		modalBase.setAttribute('style', 'display:none');
		this.dialogRef.close({ event: event });
		// let modal = document.getElementsByClassName('cdk-overlay-backdrop');
		// let modalOverlay = document.getElementsByClassName('cdk-global-overlay-wrapper');
		// let modalContainer = document.getElementsByClassName('cdk-overlay-dark-backdrop');
		// for (let index = 1; index < modal.length; index++) {
		//   modal[index].remove();
		//   modalOverlay[index].remove();
		// }
		// modalContainer[0].setAttribute('style','display:none');
		// modalContainer[0].remove()

	}






}
