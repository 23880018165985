import { Component, OnInit, HostListener, ElementRef, Input, DoCheck, ChangeDetectorRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { VerticalHeaderOpenService } from './vertical-header-open.service';

import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
	MatSnackBar, MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Helper } from 'src/helper/helper';
import { MatDialog } from '@angular/material/dialog';
import { PriceBlockModalComponent } from 'src/app/price-block-modal/price-block-modal.component';
import { OpenComponent } from './../open.component';

@Component({
	selector: 'app-vertical-header-open',
	templateUrl: './vertical-header-open.component.html',
	styleUrls: ['./vertical-header-open.component.scss']
})

@Injectable({
	providedIn: 'root'
})

export class VerticalAppHeaderOpenComponent implements DoCheck {

	@Input() callbackFunction!: (element: any) => void;
	@Input() titleKey: any;

	public config: PerfectScrollbarConfigInterface = {};
	isAllowed: boolean = true;
	dataAssociation: any;
	engagementCompleted: any;
	countAssociationPending = 0;
	message: any;
	message1: any;

	menuOpen: any = false;
	versionProduct: any;
	functions: any;
	viewButtonMyProfile = false;
	viewButtonCompanyProfile = false;
	viewButtonManageUsers = false;
	viewButtonMyPlans = false;
	viewButtonManageUsersSupplier = false;

	horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
	verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom

	numberToken: any;

	text: any;
	private wasInside = false;

	creditsTotal: any;
	creditsUser: any;
	namePlanCurrent: any;
	price: any;
	limit: any;
	fullBar: any;

	oldProfile = true;
	allNotifications: any = [];
	countModaleExpired = 0;
	newMessagesLed: any = 0;
	url: any;
	isEsprinet: any = false;
	@HostListener('click')
	clickInside() {
		this.text = "clicked inside";
		this.menuOpen = true;
		this.wasInside = true;
	}

	@HostListener('document:click')
	clickout() {
		if (!this.wasInside) {
			this.text = "clicked outside";
			this.menuOpen = false;
			this.rotateIconDown(0);
			this.rotateIconDown(1);
		}
		this.wasInside = false;
	}

	// This is for Notifications
	notifications: Object[] = [
	];

	public selectedLanguage: any = {
		language: 'English',
		code: 'en',
		type: 'ESP',
		icon: 'ENG'
	}

	public languages: any[] = [{
		language: 'English',
		code: 'en',
		type: 'ESP',
		view: 'ENG',
		icon: 'ENG'
	},
	{
		language: 'Italian',
		code: 'it',
		view: 'ITA',
		icon: 'ITA'
	}]

	profileImage = "assets/images/users/avatarDefault.svg";
	assumedUser: any;
	token: any;
	statusCompanyProfile: any;
	titleInvite: any;
	titlePosition: any;
	message2: any;
	titleCompanyProfile: any;
	titleCreateProject: any;
	message3: any;
	cbInstance: any
	state: any;
	idPlanCurrent: any;
	domain: any;
	showAlertPlan = false;
	isProd = false;
	limitations: any = [];
	limitationsArray: any = [];
	manageUserBlock: any;
	discoverCompatibleProfileBlock: any;
	timeMaterialBlock: any;
	fixedPriceBlock: any;
	showEstimateBlock: any;
	hireBlock: any;
	chatBlock = { blocked: false, action: 1 }!;
	globalState = { idPlanCurrent: 0, isOldPlan: false }!;

	viewButtonChat = false;
	downloadCompanyReportBlock: any;
	searchPartnerBlock: any;
	loadedContent = false
	modalAddOn = false
	constructor(private cdRef: ChangeDetectorRef, public dialog: MatDialog, private http: HttpClient, private translate: TranslateService, private _service: VerticalHeaderOpenService, private router: Router, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public openComponent: OpenComponent, private _snackBar: MatSnackBar, private _helper: Helper,) {

		this.matIconRegistry.addSvgIcon(
			`notification-bell`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/topbr-icons/notification-bell.svg`)
		);

		this.matIconRegistry.addSvgIcon(
			`company-profile-custom`,
			this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/new-card-icons/company-white.svg`)
		);
		if (window.location.host == 'marketplace.timeflow.it') {
			this.cbInstance = (<any>window)['Chargebee'].init({
				site: "timeflow"
			});
		} else {
			this.cbInstance = (<any>window)['Chargebee'].init({
				site: "timeflow-test"
			});
		}

		this.cbInstance.setPortalSession(() => {
			return this.http.get(this._helper.getBaseUrl() + 'chargebee/getPortalSession', { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${this._helper.getUserConfigData().access_token}` }) }).toPromise();
		});

		this.functions = JSON.parse(sessionStorage.getItem("functions")!);

		if (this.functions && this.functions.length != 0) {
			for (let i = 0; i < this.functions.length; i++) {
				if (this.functions[i]['state'] == 'my_profile') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonMyProfile = true;
					} else {
						this.viewButtonMyProfile = false;
					}
				}
				if (this.functions[i]['state'] == 'company_profile') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonCompanyProfile = true;
					} else {
						this.viewButtonCompanyProfile = false;
					}
				}
				if (this.functions[i]['state'] == 'manage_users') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonManageUsers = true;
					} else {
						this.viewButtonManageUsers = false;
					}
				}
				if (this.functions[i]['state'] == 'my_plans') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonMyPlans = true;
					} else {
						this.viewButtonMyPlans = false;
					}
				}
				if (this.functions[i]['state'] == 'manage_users_supplier') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonManageUsersSupplier = true;
					} else {
						this.viewButtonManageUsersSupplier = false;
					}
				}
				if (this.functions[i]['state'] == 'chat') {
					if (this.functions[i]['status'] == 1) {
						this.viewButtonChat = true;
					} else {
						this.viewButtonChat = false;
					}
				}
			}
		} else {
			this.functions = [];
		}

		console.log(1);

		var language = sessionStorage.getItem("language");
		if (language) {
			if (language == 'it') {
				this.selectedLanguage.code = 'it';
				this.selectedLanguage.view = "ITA";
				this.selectedLanguage.language = "Italian";
				translate.setDefaultLang('it');
			} else {
				this.selectedLanguage.code = 'en';
				this.selectedLanguage.view = "ENG";
				this.selectedLanguage.language = "English";
				translate.setDefaultLang('en');
			}
			// sessionStorage.removeItem('language');

		} else {
			translate.setDefaultLang('it');
			this.selectedLanguage.code = 'it';
			this.selectedLanguage.view = "ITA";
			this.selectedLanguage.language = "Italian";
		}

	}
	changeLanguage(lang: any) {
		let token = sessionStorage.getItem('tt')
		if (token == null || token == undefined || token.length == 0) {
			token = this.openComponent.token;
		}
		this.translate.use(lang.code)
		this.selectedLanguage = lang;
		sessionStorage.setItem('language', lang.code)

		this._service.changeLanguageOpen(token, lang.code).then(res => {
			if (res.success) {
				this.callbackFunction(this.titleKey);
				window.location.reload();
			} else {
				this._snackBar.open(res.message, '', {
					duration: 5000,
					panelClass: ['danger-snackbar'],
					horizontalPosition: this.horizontalPosition,
					verticalPosition: this.verticalPosition,

				});
			}

		}).catch(error => {

			console.log(error);

		});

	}

	openBlockModal(type: any) {
		let dialogRef;
		var obj;
		if (type == 'chat') {
			obj = this.chatBlock;
			obj.action = 1;
		} else {
			obj = this.manageUserBlock;
			obj.action = 2;
		}
		obj.sourcePage = type == 'chat' ? 'Header  - Chat' : 'Header  - Blocco utenze';

		dialogRef = this.dialog.open(PriceBlockModalComponent, {
			data: obj
		});

		dialogRef.afterClosed().subscribe(result => {

		})
	}
	goToNotification() {
		this.router.navigate(['/notifications'])
	}

	ngAfterViewChecked(): void {
		this.manageUserBlock = this.openComponent.manageUserBlock
		this.discoverCompatibleProfileBlock = this.openComponent.discoverCompatibleProfileBlock
		this.timeMaterialBlock = this.openComponent.timeMaterialBlock
		this.fixedPriceBlock = this.openComponent.fixedPriceBlock
		this.showEstimateBlock = this.openComponent.showEstimateBlock
		this.hireBlock = this.openComponent.hireBlock
		this.chatBlock = this.openComponent.chatBlock
		this.state = this.openComponent.state
		this.globalState = this.openComponent.globalState

		////console.log(this.globalState);
		this.cdRef.detectChanges();
		// BLOCCHI DA FULL COMPONENT
		setTimeout(() => {
			this.downloadCompanyReportBlock = this.openComponent.downloadCompanyReportBlock
			this.searchPartnerBlock = this.openComponent.searchPartnerBlock

			// BLOCCHI DA FULL COMPONENT
			this.loadedContent = true

			if (this.searchPartnerBlock == undefined || this.searchPartnerBlock == null) {
				this.modalAddOn = false
			} else {
				if (this.searchPartnerBlock.blocked == true) {
					this.modalAddOn = false
				} else {
					this.modalAddOn = true
				}
			}

			this.cdRef.detectChanges();
		}, 1);

	}

	manageAccount() {
		this.cbInstance.createChargebeePortal().open({
			loaded: function () {

			},
			close: function () {
				//console.log("close portal");
			},
			paymentSourceAdd: function (status: any) {
				//console.log("payment source add" + status);
			},
			paymentSourceUpdate: function (status: any) {
				//console.log("payment source update" + status);
			},
			paymentSourceRemove: function (status: any) {
				//console.log("payment source removed");
			},
			visit: function (value: any) {
				//console.log(value);
			}
		});
	}

	goToMyPlans() {
		this.showAlertPlan = false;
		sessionStorage.setItem('backRequest', window.location.href);
		sessionStorage.removeItem('menuVoice');
		sessionStorage.setItem('menuVoice', JSON.stringify('My Plans'));
		this.router.navigate(['/my_plans']);
	}

	calculatePercentage($total: any, $remaining: any) {
		this.fullBar = (100 * (parseInt($remaining, 10))) / parseInt($total, 10);
		this.fullBar = Math.floor(this.fullBar);
		this.fullBar = 100 - this.fullBar;
	}

	ngOnInit(): void {

		if (window.location.hostname.toLowerCase().includes('esprinet')) {
			this.isEsprinet = true
		}

	}

	ngDoCheck() {

		let images = (sessionStorage.getItem("imageFreelance")!)
		if (images) {
			this.profileImage = images;
			sessionStorage.removeItem("imageFreelance");
		}
		var completeProfile = sessionStorage.getItem("complete-profile");
		var planPurchased = sessionStorage.getItem('plan-purchased');

		if (completeProfile == "true") {
			this.notifications = [];
			sessionStorage.removeItem("complete-profile");
		}

	}

	manageError() {

	}

	returnAdmin() {

		this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		this.token = JSON.parse(sessionStorage.getItem("loginUser")!);

		this.url = window.location.hostname;

		this._service.getParamLogin(this.token.token).then(res => {
			if (res.success) {

				sessionStorage.setItem('loginUser', JSON.stringify(res.data.loginUser));
				sessionStorage.setItem('loginUserToken', JSON.stringify(res.data.loginUserToken));
				sessionStorage.setItem('descriptionPages', JSON.stringify(res.data.descriptionPages));
				sessionStorage.setItem('tutorialPage', JSON.stringify(res.data.tutorialPage));
				sessionStorage.setItem('assumedUser', JSON.stringify(res.data.loginUser.assumedUser));
				sessionStorage.setItem('versionProduct', JSON.stringify(res.data.versionProduct));
				sessionStorage.setItem('functions', JSON.stringify(res.data.functions));
				sessionStorage.setItem('possibleAssumeRole', JSON.stringify(res.data.loginUser.possibleAssumeRole));

				sessionStorage.setItem('showTypeform', JSON.stringify(0));

				window.location.href = res.data.domain_client + res.data.url_redirect;

			} else {
				this.router.navigate(['/authentication/login']);
			}
		}).catch(error => {

			this.router.navigate(['/authentication/login']);

		});

		// let _1_loginUser:any = JSON.parse(sessionStorage.getItem("_1_loginUser")!)
		// let _loginUser: any = JSON.parse(sessionStorage.getItem("_loginUser")!)
		// let zloginUser: any = JSON.parse(sessionStorage.getItem("loginUser")!)

		// let have_1_login_user = false;
		// let have_login_user = false;
		// let havelogin_user = false;

		// if (_1_loginUser != undefined && _1_loginUser != null){
		//   have_1_login_user = true
		// }
		// if (_loginUser != undefined && _loginUser != null) {
		//   have_login_user = true
		// }
		// if (zloginUser != undefined && zloginUser != null) {
		//   havelogin_user = true
		// }

		// if (this.versionProduct == 1 && this.url.includes("esprinet") && have_1_login_user == true) {

		//   if (have_login_user == true && have_1_login_user == true) {

		//     /* IA - Provengo da platform e ho seguito il seguente percoso staffTimeflow->BackOffice->Azienda cliente/fornitore */
		//     /* IA - Ora sono in un'azienda cliente e devo ritornare nel BackOffice */
		//     sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUser")!)));

		//     sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUserToken")!)));
		//     sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));

		//     sessionStorage.removeItem('_1_loginUser');
		//     sessionStorage.removeItem('_1_loginUserToken');
		//     sessionStorage.removeItem('_descriptionPages');

		//     sessionStorage.removeItem('loginUserSupplier');
		//     sessionStorage.removeItem('loginUserTokenSupplier');
		//     sessionStorage.removeItem('descriptionPagesSupplier');
		//     sessionStorage.removeItem('tutorialPageSupplier');

		//   } else if (have_1_login_user == true && have_login_user == false) {

		//     /* IA - Provengo da backoffice e ho seguito il seguente percoso BackOffice->Azienda cliente/fornitore */
		//     /* IA - Ora sono nel backOffice e devo ritornare in platform */

		//     sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUser")!)));
		//     sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_1_loginUserToken")!)));
		//     sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));
		//     sessionStorage.setItem('assumedUser', JSON.stringify(0));

		//     sessionStorage.removeItem('_1_loginUser');
		//     sessionStorage.removeItem('_1_loginUserToken');
		//     sessionStorage.removeItem('_descriptionPages');
		//     sessionStorage.removeItem('loginUserSupplier');
		//     sessionStorage.removeItem('loginUserTokenSupplier');
		//     sessionStorage.removeItem('descriptionPagesSupplier');
		//     sessionStorage.removeItem('tutorialPageSupplier');

		//   }

		//   var loginUser = JSON.parse(sessionStorage.getItem("loginUser")!);

		//   this.domain = loginUser.redirect_url + '/company_list';

		//   window.location = this.domain;

		// } else {
		//   sessionStorage.setItem('loginUser', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUser")!)));
		//   sessionStorage.setItem('loginUserToken', JSON.stringify(JSON.parse(sessionStorage.getItem("_loginUserToken")!)));
		//   sessionStorage.setItem('descriptionPages', JSON.stringify(JSON.parse(sessionStorage.getItem("_descriptionPages")!)));
		//   sessionStorage.setItem('assumedUser', JSON.stringify(0));

		//   sessionStorage.removeItem('_loginUser');
		//   sessionStorage.removeItem('_loginUserToken');
		//   sessionStorage.removeItem('_descriptionPages');
		//   sessionStorage.removeItem('loginUserSupplier');
		//   sessionStorage.removeItem('loginUserTokenSupplier');
		//   sessionStorage.removeItem('descriptionPagesSupplier');
		//   sessionStorage.removeItem('tutorialPageSupplier');

		//   this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
		//   if (this.versionProduct == 2) {
		//     window.location.href = '/company';
		//   } else if (this.versionProduct == 1) {
		//     var loginUser = JSON.parse(sessionStorage.getItem("loginUser")!);
		//     this.domain = loginUser.redirect_url + '/company';
		//     window.location = this.domain;
		//   }
		// }

	}

	goToOtherPage(page: any) {
		sessionStorage.removeItem('menuVoice');
		if (page == 'my_profile') {
			sessionStorage.setItem('menuVoice', JSON.stringify('My Profile'));
			this.router.navigate(['/my_profile']);
		} else if (page == 'register') {

			this.versionProduct = sessionStorage.getItem('versionProduct');

			var objSegment = {};

			objSegment = {
				action: 'sign_up',
				page_name: 'Top Bar',
				dettaglio_profilo: 0,
				id_profilo: 'null',
				source: 'Topbar'

			};

			if (this.openComponent.activeSegmentDev == true) {

				this.openComponent.trackSegment(objSegment);
			}

			if (this.versionProduct == 2) {
				/* IA - SEGMENT */
				if (window.location.host == 'marketplace.timeflow.it') {
					this.openComponent.trackSegment(objSegment);
				}

			} else {

				if (window.location.host == 'localhost:4200') {

				} else {

					if (window.location.host == "procurement.timeflow.it") {
						this.openComponent.trackSegment(objSegment);
					} else if (window.location.host == "whitelabel.daytrend.it") {

					} else if (window.location.host == "procurement.daytrend.it") {

					} else if (window.location.host == "dev.mkt.daytrend.it") {

					} else {
						if (!(window.location.host.includes('daytrend'))) {

							this.openComponent.trackSegment(objSegment);
						}
					}
				}
			}

			window.open('/authentication/register?role_id=2&utm_source=open', "_self");
		}

	}

	changePages(notification: any) {

	}

	rotateIcon(iconnumber: any) {

	}

	rotateIconDown(iconnumber: any) {

	}

	logout() {

		if ((window.location.host == 'marketplace.timeflow.it' && !window.location.host.includes('open/') && this.versionProduct == 2)) {

			setTimeout(() => {
				const status = window.HubSpotConversations.widget.status();

				if (status.loaded) {
					window.HubSpotConversations.widget.refresh();
				} else {
					window.HubSpotConversations.widget.load();
				}

			}, 500);
		}

		sessionStorage.clear();
		localStorage.clear();
		this.router.navigate(['/']);

	}

	changeInternalLang(lang: any) {
		console.log('entrato')
		if (lang == 'it') {
			this.selectedLanguage.code = 'it';
			this.selectedLanguage.view = "ITA";
			this.selectedLanguage.language = "Italian";
			this.translate.setDefaultLang('it');
		} else {
			this.selectedLanguage.code = 'en';
			this.selectedLanguage.view = "ENG";
			this.selectedLanguage.language = "English";
			this.translate.setDefaultLang('en');
		}
	}

}
